import {LocalConfig} from "@/config";
export const LocationDefine = {
  KE: {
    name: "Kenya",
    code: "KE",
    currency: "KES",
    currencyName: "Kenyan Shilling",
    phoneStartsWith: "254",
    lang: "en",
    flag: require("@/assets/images/header/flag_kenya.png").default,
    domain: "luka5.com",
    url: "https://luka5.com",
  }
};

/**
 *
 * @param {string | null} locationKey
 * @returns {name, code, currency, currencyName, phoneStartsWith, lang, flag, domain, url}
 */
export function LocationInfo(locationKey) {
  if (!locationKey) {
    locationKey = LocalConfig.locationKey;
  }
  let upperLocationKey = locationKey.toUpperCase();
  if (!LocationDefine[upperLocationKey]) {
    upperLocationKey = "KE";
  }
  return LocationDefine[upperLocationKey];
}
