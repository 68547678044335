import { LocalConfig, NumericalConfig } from "@/config";

//获取大厅服务器地址
export const GetHallServerUrl = () => {
  if (localStorage.getItem("hall_url")) {
    console.log("GetHallServerUrl", localStorage.getItem("hall_url"));
    return localStorage.getItem("hall_url");
  }
  let hallServerUrl = process.env.PREACT_APP_ENV_CONFIG.hallServerUrl;
  if (process.env.PREACT_APP_ENV_CONFIG.BUILD_FOR == "TEST") {
    // 把前面的192.168.*.*替换成当前的ip，方便手机调试
    hallServerUrl = hallServerUrl.replace(/192\.168\.\d{1,3}\.\d{1,3}/, window.location.hostname);
  }
  return hallServerUrl;
};
//获取自建打点服地址
export const GetWebcountServerUrl = () => {
  if (localStorage.getItem("webcount_url")) {
    console.log("GetWebcountServerUrl", localStorage.getItem("webcount_url"));
    return localStorage.getItem("webcount_url");
  }
  let webcountServerUrl = process.env.PREACT_APP_ENV_CONFIG.webcountServerUrl;
  if (process.env.PREACT_APP_ENV_CONFIG.BUILD_FOR == "TEST") {
    // 把前面的192.168.*.*替换成当前的ip，方便手机调试
    webcountServerUrl = webcountServerUrl.replace(/192\.168\.\d{1,3}\.\d{1,3}/, window.location.hostname);
  }
  return webcountServerUrl;
};
//获取游戏资源地址
export const GetGameUrl = (gameVersion) => {
  let gameUrl = process.env.PREACT_APP_ENV_CONFIG[`game${gameVersion}Url`];
  if (process.env.PREACT_APP_ENV_CONFIG.BUILD_FOR == "TEST") {
    // 把前面的192.168.*.*替换成当前的ip，方便手机调试
    gameUrl = gameUrl.replace(/192\.168\.\d{1,3}\.\d{1,3}/, window.location.hostname);
  }
  return gameUrl;
};

export const GetInviteUrl = (aid) => {
  return `${window.location.origin}/home?aid=${aid}`;
};

export const GetWhatsAppInviteUrl = (aid) => {
  return encodeURI(
    `whatsapp://send?text=Come and join my GameLuka team. Click and Register to receive  cash ${(
      NumericalConfig.inviteWheel.targetAmount / 100
    ).toFixed(2)} and withdraw cash Now\n${GetInviteUrl(aid)}`
  );
};
export const DownloadApkUrl = `${process.env.PREACT_APP_ENV_CONFIG.apkUrl}/luka_android.apk`; //本项目地址
export const GetFacebookInviteUrl = (rid, text) => {
  return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${GetInviteUrl(rid)}&t=${text}`);
};

//免费转盘分享url
export const GetInviteWheelUrl = (rid) => {
  return `${window.location.origin}/home?invite_wheel_inviter=${rid}`;
};

export const GetWhatsAppInviteWheelUrl = (rid) => {
  //也可以用这个`https://api.whatsapp.com/send?text=` + encodeURIComponent("Get {LocalConfig.currencyCode} ${(NumericalConfig.inviteWheel.targetAmount/100).toFixed(0)} for free") + encodeURIComponent("\n\n" + GetInviteWheelUrl(rid));
  return encodeURI(
    `whatsapp://send?text=Get ${LocalConfig.currencyCode} ${(NumericalConfig.inviteWheel.targetAmount / 100).toFixed(
      0
    )} for free\n${GetInviteWheelUrl(rid)}`
  );
};

export const GetTelegramInviteWheelUrl = (rid, text) => {
  return encodeURI(`https://t.me/share/url?url=${GetInviteWheelUrl(rid)}&text=${text}`);
};

export const GetTwitterInviteWheelUrl = (rid, text) => {
  return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(
    GetInviteWheelUrl(rid)
  )}`;
};

export const GetFacebookInviteWheelUrl = (rid, text) => {
  return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${GetInviteWheelUrl(rid)}&t=${text}`);
};

export const GetSeedWhatsAppInviteWheelUrl = (phone, rid, text) => {
  return encodeURI(`https://api.whatsapp.com/send?phone=${phone}&text=${text}\n\n${GetInviteWheelUrl(rid)}`);
};

export const GetSmsInviteWheelUrl = (phoneNumbers, rid, text) => {
  return encodeURI(`sms:/open?addresses=${phoneNumbers.join(",")}&body=${text}${GetInviteWheelUrl(rid)}`);
};
